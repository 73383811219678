import { RouteConfig } from 'vue-router';
import authService from '@/services/auth/auth.service';

const routes: RouteConfig = {
  path: '/',
  name: 'Home',
  component: () => import('../views/Home.vue'),
  children: [
    {
      name: 'Dashboard',
      path: '/dashboard',
      component: () => import('../components/Dashboard/Dashboard.vue'),
    },
    {
      name: 'Package',
      path: '/payment-package',
      component: () =>
        import('../components/PaymentPackage/PaymentPackage.vue'),
    },
    {
      name: 'Customer',
      path: '/customer',
      component: () => import('../components/Customer/Customer.vue'),
    },
    {
      name: 'CustomerDetail',
      path: '/customer/:id',
      component: () =>
        import('../components/Customer/components/CustomerDetail.vue'),
    },
    {
      name: 'Bot Settings',
      path: '/bot-settings',
      component: () =>
        import('../components/DefaultSetting/DefaultSetting.vue'),
    },
  ],
  beforeEnter: (_to, _from, next) => {
    if (authService.isLoggedIn()) {
      next();
    } else {
      next('/login');
    }
  },
};
export default routes;
