

































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import LineChart from './components/LineChart.vue';
import PieChart from './components/PieChart.vue';
import DataTable from './components/DataTable.vue';
import DashboardService from '@/services/dashboard/dashboard.service';
import { IResponse } from '@/interfaces/response.interface';

export default Vue.extend({
  components: {
    LineChart,
    PieChart,
    DataTable,
  },
  data: () => {
    return {
      dataTable: [],
    };
  },
  async created() {
    await this.getDataTable();
  },
  methods: {
    async getDataTable() {
      const workspaces: IResponse = await DashboardService.getWorkspaceData();
      this.dataTable = await Promise.all(
        workspaces.data.data.map(async (workspace: any) => {
          const workspaceName = workspace.workspaceName;
          const membersData: IResponse = await DashboardService.getTeamsOfWorkspaceData(
            workspace.workspaceId,
          );
          const reportsData: IResponse = await DashboardService.getReportOfWorkspaceData(
            workspace.workspaceId,
          );
          return {
            name: workspaceName,
            members: membersData.data.data.length,
            reports: reportsData.data.data.length,
            messages: reportsData.data.data.reduce(
              (a: number, b: any) => a + b.messages,
              0,
            ),
            plan: 'Free',
            paid: 0,
          };
        }),
      );
    },
  },
});
