import router from '@/router';
import axios from 'axios';
import AuthService from '../services/auth/auth.service';

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // baseURL: process.env.VUE_APP_DEV_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});
axiosClient.interceptors.request.use(async config => {
  console.log(process.env.VUE_APP_BASE_URL, process.env.VUE_APP_DEV_BASE_URL);
  if (AuthService.isLoggedIn()) {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  return config;
});

axiosClient.interceptors.response.use(
  response => {
    // console.log(response);
    return response;
  },
  error => {
    let path = '/error';
    switch (error.response.status) {
      case 401:
        localStorage.removeItem('token');
        path = '/login';
        break;
      case 403:
        path += '/403';
        break;
      case 404:
        path += '/404';
        break;
      case 500:
        path += '/500';
        break;
    }
    router.push(path);
    return error;
  },
);
export default axiosClient;
